<mgl-timeline>
  <mgl-timeline-entry>
    <mgl-timeline-entry-header>
      <button
        mat-raised-button
        color="primary"
        style="max-width: 95%; width: 100%; font-size: large"
      >
        Amazon | Software Engineer
      </button>
    </mgl-timeline-entry-header>
    <mgl-timeline-entry-dot></mgl-timeline-entry-dot>
    <mgl-timeline-entry-side style="color: antiquewhite; text-align: right"
      ><mat-card style="margin-top: 1%; margin-left: 10px">
        <mat-card-content style="font-size: medium">
          July 2021 - Present
        </mat-card-content>
      </mat-card>
    </mgl-timeline-entry-side>
    <mgl-timeline-entry-content>
      <ul style="font-size: medium">
        <li>Seller Payments</li>
      </ul>
    </mgl-timeline-entry-content>
  </mgl-timeline-entry>
  <mgl-timeline-entry>
    <mgl-timeline-entry-header>
      <button
        mat-raised-button
        color="primary"
        style="max-width: 95%; width: 100%; font-size: large"
      >
        University of Toronto | Teaching Assistant
      </button>
    </mgl-timeline-entry-header>
    <mgl-timeline-entry-dot></mgl-timeline-entry-dot>
    <mgl-timeline-entry-side style="color: antiquewhite; text-align: left"
      ><mat-card style="margin-top: 1%; margin-right: 10px">
        <mat-card-content style="font-size: medium">
          September 2020 - December 2020
        </mat-card-content>
      </mat-card>
    </mgl-timeline-entry-side>
    <mgl-timeline-entry-content>
      <ul style="font-size: medium">
        <li>CSCA20: Introduction to Programming</li>
      </ul>
    </mgl-timeline-entry-content>
  </mgl-timeline-entry>
  <mgl-timeline-entry>
    <mgl-timeline-entry-header>
      <button
        mat-raised-button
        color="primary"
        style="max-width: 95%; width: 100%; font-size: large"
      >
        Interac Corp. | Software Developer Intern
      </button>
    </mgl-timeline-entry-header>
    <mgl-timeline-entry-dot></mgl-timeline-entry-dot>
    <mgl-timeline-entry-side style="color: antiquewhite; text-align: right"
      ><mat-card style="margin-top: 1%; margin-left: 10px">
        <mat-card-content style="font-size: medium">
          May 2020 - August 2020
        </mat-card-content>
      </mat-card>
    </mgl-timeline-entry-side>
    <mgl-timeline-entry-content>
      <ul style="font-size: medium">
        <li>
          Built a shared expense tracking system which generates bulk eTransfers
          using Interac request money API increasing user efficiency by 70%.
        </li>
        <li>
          Lead team of 4 to build a web application (<strong>React</strong>) to
          generate QR payment capabilities through Interac eTransfer API.
        </li>
        <li>
          Ported web application into mobile interface using
          <strong>Ionic Framework</strong> enabling rich mobile experience and
          cross platform compatibility.
        </li>
        <li>
          Improved <strong>Flutter</strong> application to extend functionality
          of QR payments for Merchant and Buyer.
        </li>
      </ul>
    </mgl-timeline-entry-content>
  </mgl-timeline-entry>
  <mgl-timeline-entry>
    <mgl-timeline-entry-header>
      <button
        mat-raised-button
        color="primary"
        style="max-width: 95%; width: 100%; font-size: large"
      >
        Developer Students Club | VP of Technology
      </button>
    </mgl-timeline-entry-header>
    <mgl-timeline-entry-dot></mgl-timeline-entry-dot>
    <mgl-timeline-entry-side style="color: antiquewhite; text-align: left"
      ><mat-card style="margin-top: 1%; margin-right: 10px">
        <mat-card-content style="font-size: medium">
          September 2019 - April 2020
        </mat-card-content>
      </mat-card>
    </mgl-timeline-entry-side>
    <mgl-timeline-entry-content>
      <ul style="font-size: medium">
        <li>
          Helping organize <strong>Cloud Study Jam</strong> events across campus
          for students interested in developer technologies, targeted at
          students with nontechnical background.
        </li>
        <li>
          Leading teams for internal software development projects and teaching
          new technologies to junior developers.
        </li>
        <li>
          Helped students finish qwiklabs quest regarding NLP and Google Cloud
          Services and navigate the Google Cloud Console.
        </li>
      </ul>
    </mgl-timeline-entry-content>
  </mgl-timeline-entry>
  <mgl-timeline-entry>
    <mgl-timeline-entry-header>
      <button
        mat-raised-button
        color="primary"
        style="max-width: 95%; width: 100%; font-size: large"
      >
        Royal Bank of Canada | Full Stack Developer Intern
      </button>
    </mgl-timeline-entry-header>
    <mgl-timeline-entry-dot></mgl-timeline-entry-dot>
    <mgl-timeline-entry-side style="color: antiquewhite; text-align: right"
      ><mat-card style="margin-top: 1%; margin-left: 10px">
        <mat-card-content style="font-size: medium">
          January 2019 - August 2019
        </mat-card-content>
      </mat-card>
    </mgl-timeline-entry-side>
    <mgl-timeline-entry-content>
      <h2>Front End</h2>
      <h3>Angular</h3>
      <ul style="font-size: medium">
        <li>
          Using <strong>Angular Material </strong> developed easy to use forms
          for user to fill out.
        </li>
        <li>
          Implemented authentication guards to restrict users from accessing non
          authorized features.
        </li>
        <li>
          Refactored several components to improve user experience and
          interface.
        </li>
        <li>
          Using <strong>Grafana</strong> displayed analytics and monitoring
          inside the application.
        </li>
      </ul>
      <h2>Back End</h2>
      <h3>Play Framework (Scala)</h3>
      <ul style="font-size: medium">
        <li>
          Implemented endpoints to store data in
          <strong>MongoDB</strong> database and retrieve data.
        </li>
      </ul>
      <h3>Flask (Python)</h3>
      <ul style="font-size: medium">
        <li>
          Using various <strong>Apache</strong> projects, implemented endpoints
          to use the particular project.
        </li>
        <li>
          Performed Unit Testing using <strong>PyTest</strong> to ensure
          features are working with new changes.
        </li>
        <li>
          Implemented generating report functionality by combining data from
          <strong>MongoDB</strong> and <strong>MySQL</strong>.
        </li>
      </ul>
    </mgl-timeline-entry-content>
  </mgl-timeline-entry>
</mgl-timeline>
