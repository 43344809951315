<link
  href="https://fonts.googleapis.com/css?family=Neucha&display=swap"
  rel="stylesheet"
/>
<mat-grid-list
  [cols]="breakpoint"
  [rowHeight]="colsToRow"
  (window:resize)="onResize($event)"
>
  <mat-grid-tile
    style="background: transparent"
    [colspan]="breakpoint === 1 ? 1 : breakpoint - 1"
    [rowspan]="innerWidth < 450 ? 2 : 1"
    ><mat-card class="card">
      <mat-card-content style="font-size: medium; height: 100%">
        Hey! I'm Smit! <br /><br />
        I am a Software Engineer at Amazon. I have completed my Computer Science degree from the University of
        Toronto. I have previously interned at Royal Bank of Canada, and Interac Corp. I am
        always looking for new opportunities to connect with people and gain
        valuable experience!
        <br /><br />

        Thank you for taking the time to visit my website, I hope you learn more
        about myself and hope to get in touch through any preferred mediums!
      </mat-card-content>
    </mat-card></mat-grid-tile
  >
  <mat-grid-tile [colspan]="breakpoint === 1 ? 1 : breakpoint - 1">
    <img
      src="../../assets/about/amazon_logo.png"
      alt="Amazon Logo"
      width="450px"
    />
  </mat-grid-tile>
  <mat-grid-tile [colspan]="breakpoint">
    <div>
      <img
        src="../../assets/about/toronto_skyline.png"
        alt="Toronto Skyline"
        style="display: block; margin-left: auto; margin-right: auto"
      />
      <br />
      <mat-divider
        style="
          border-color: black;
          border-style: solid;
          border-width: 100%;
          width: 100%;
        "
      ></mat-divider>
      <div style="margin: auto; text-align: center">
        <a
          mat-icon-button
          aria-label="Github icon"
          href="https://github.com/smitpatel316"
          target="_blank"
        >
          <img src="../../assets/about/github.svg" />
        </a>
        &nbsp;
        <a
          mat-icon-button
          aria-label="LinkedIn icon"
          href="https://www.linkedin.com/in/smitpatel316"
          target="_blank"
        >
          <img src="../../assets/about/linkedin.png" />
        </a>
      </div>
    </div>
  </mat-grid-tile>
</mat-grid-list>
